@charset "UTF-8";

@font-face {
  font-family: "immersio";
  src:url("../fonts/immersio.eot");
  src:url("../fonts/immersio.eot?#iefix") format("embedded-opentype"),
    url("../fonts/immersio.woff") format("woff"),
    url("../fonts/immersio.ttf") format("truetype"),
    url("../fonts/immersio.svg#immersio") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "immersio" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "immersio" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter-square:before {
  content: "\61";
}
.icon-twitter:before {
  content: "\62";
}
.icon-facebook-square:before {
  content: "\63";
}
.icon-facebook:before {
  content: "\64";
}
.icon-instagram:before {
  content: "\65";
}
.icon-envelope:before {
  content: "\66";
}
.icon-envelope-square:before {
  content: "\67";
}
.icon-calendar:before {
  content: "\68";
}
.icon-ticket:before {
  content: "\69";
}
.icon-map-marker:before {
  content: "\6a";
}
.icon-clock-o:before {
  content: "\6b";
}
