/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@font-face {
  font-family: "immersio";
  src: url("../fonts/immersio.eot");
  src: url("../fonts/immersio.eot?#iefix") format("embedded-opentype"), url("../fonts/immersio.woff") format("woff"), url("../fonts/immersio.ttf") format("truetype"), url("../fonts/immersio.svg#immersio") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "immersio" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "immersio" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-twitter-square:before {
  content: "\61"; }

.icon-twitter:before {
  content: "\62"; }

.icon-facebook-square:before {
  content: "\63"; }

.icon-facebook:before {
  content: "\64"; }

.icon-instagram:before {
  content: "\65"; }

.icon-envelope:before {
  content: "\66"; }

.icon-envelope-square:before {
  content: "\67"; }

.icon-calendar:before {
  content: "\68"; }

.icon-ticket:before {
  content: "\69"; }

.icon-map-marker:before {
  content: "\6a"; }

.icon-clock-o:before {
  content: "\6b"; }
